import React from "react";
import {
  SEO,
  PageLayout,
  Section,
  DynamicTiles,
  MultipleComponentIterator,
  ContinuousScrollV2,
  Image,
  MarkdownContent,
  SlideReveal,
  FullImageCard,
  InternalLink,
  FishermanIcon,
  FadeReveal,
  BasicCarousel,
  componentIterator,
  multipleComponentIterator,
  ReviewV2,
  CustomerReviewModal,
  ScrollableContent,
  FishermanHeader,
  Location,
  Email,
  Phone,
  HoursOfOperation,
  ContinuousScroll,
  PatternedImageRow,
  OutboundLink,
  TeamMemberV2,
} from "@bluefin/components";
import { Header, Button, Grid, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withNamedArgs,
  getBusinessFiles,
} from "../utils/utils";
import { createServiceCategorySlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      dynamicTilesComponent,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessFile,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
          ogUrl={"https://www.bobsteele.com/"}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <Section className={"custom-hero-section"}>
            <DynamicTiles className={"custom-hero"}>
              <MultipleComponentIterator
                iterator={dynamicTilesComponent.childConfigurations}
                components={[
                  {
                    component: (
                      <DynamicTiles.Tile
                        imageProps={{ loadStrategy: "eager" }}
                      />
                    ),
                    propMap: {
                      images: "files",
                      content: "contentNodes",
                      interactions: "interactions",
                      className: "data.className",
                      overlayClassName: "data.overlayClassName",
                      width: "data.width",
                      video: "data.video",
                    },
                  },
                ]}
              />
            </DynamicTiles>
          </Section>
          <Section className={"trusted-by-section custom-trusted-section"}>
            <ContinuousScrollV2
              loadStrategy={"inViewport"}
              sizePatterns={[{ width: 100, height: 50, alignment: "middle" }]}
              items={[
                <ContinuousScrollV2.Item>
                  <Image
                    loadStrategy={"eager"}
                    src={
                      "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/2025_EAST_COBB_Best_Hair_Salon.png"
                    }
                  />
                </ContinuousScrollV2.Item>,
                <ContinuousScrollV2.Item>
                  <Image
                    loadStrategy={"eager"}
                    src={
                      "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/MY_BUCKHEAD_2025_Best_Hair_Salon.png"
                    }
                  />
                </ContinuousScrollV2.Item>,
                <ContinuousScrollV2.Item>
                  <Image
                    loadStrategy={"eager"}
                    src={
                      "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/unnamed-6.png"
                    }
                  />
                </ContinuousScrollV2.Item>,
                <ContinuousScrollV2.Item>
                  <Image
                    loadStrategy={"eager"}
                    src={
                      "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/unnamed.png-2.png"
                    }
                  />
                </ContinuousScrollV2.Item>,
                <ContinuousScrollV2.Item>
                  <Image
                    loadStrategy={"eager"}
                    src={
                      "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/Bob_Steele_Salon-Neighborhood_recommended.png"
                    }
                  />
                </ContinuousScrollV2.Item>,
                <ContinuousScrollV2.Item>
                  <Image
                    loadStrategy={"eager"}
                    src={
                      "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/2024_Best_of_MY_BUCKHEAD_generic-winner.jpg"
                    }
                  />
                </ContinuousScrollV2.Item>,
                <ContinuousScrollV2.Item>
                  <Image
                    loadStrategy={"eager"}
                    src={
                      "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/My_Vinings-Smyrna_2024_Best_Hair_Salon.png"
                    }
                  />
                </ContinuousScrollV2.Item>,
                <ContinuousScrollV2.Item>
                  <Image
                    loadStrategy={"eager"}
                    src={
                      "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/131897_4acce8723b0940f38e33de3f53a7350emv2-1.png"
                    }
                  />
                </ContinuousScrollV2.Item>,
                <ContinuousScrollV2.Item>
                  <Image
                    loadStrategy={"eager"}
                    src={
                      "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/unnamed.png"
                    }
                  />
                </ContinuousScrollV2.Item>,
                <ContinuousScrollV2.Item>
                  <Image
                    loadStrategy={"eager"}
                    src={
                      "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/131897_38f2e205801440f69e96eb45dc3f69e1mv2.png"
                    }
                  />
                </ContinuousScrollV2.Item>,
                <ContinuousScrollV2.Item>
                  <Image
                    loadStrategy={"eager"}
                    src={
                      "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/131897_66a0b161da844aa4a51a650628d25791mv2.png"
                    }
                  />
                </ContinuousScrollV2.Item>,
                <ContinuousScrollV2.Item>
                  <Image loadStrategy={"eager"} src={""} />
                </ContinuousScrollV2.Item>,
                <ContinuousScrollV2.Item>
                  <Image
                    loadStrategy={"eager"}
                    src={
                      "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/unnamed-2.png"
                    }
                  />
                </ContinuousScrollV2.Item>,
              ]}
            />
          </Section>
          <Section className={"services-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "services_section_header",
                defaultValue: "Our Services",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "services_section_description",
              })}
            />
            <Card.Group itemsPerRow={4}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessMenuCategory.nodes}
                limit={8}
                components={[
                  {
                    component: (
                      <SlideReveal
                        className={"ui card"}
                        direction={"up"}
                        triggerOnce={true}
                        fade={true}
                      />
                    ),
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <FullImageCard
                            as={InternalLink}
                            link={true}
                            event={{
                              category: "Services",
                              action: "View Category Details",
                            }}
                            className={"category-card"}
                          />
                        ),
                        propMap: {
                          image: withNamedArgs({
                            func: getBusinessFiles,
                            args: {
                              businessFiles: allFishermanBusinessFile.nodes,
                              numToSelect: 1,
                            },
                          }),
                          "event.label": "name",
                          to: createServiceCategorySlug,
                        },
                        children: [
                          {
                            component: <Card.Header />,
                            children: [
                              {
                                component: <span />,
                                propMap: { children: "name" },
                              },
                              {
                                component: (
                                  <FishermanIcon iconName={"arrow-right"} />
                                ),
                              },
                            ],
                          },
                          {
                            component: (
                              <MarkdownContent className={"description"} />
                            ),
                            propMap: { content: "description" },
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
            <div className={"see-all-services-container"}>
              <Button
                primary={true}
                event={{ category: "Services", action: "View All Services" }}
                as={InternalLink}
                to={"/services/"}
              >
                <span
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "see_all_services_button",
                    defaultValue: "Discover More Services",
                  })}
                />
              </Button>
            </div>
          </Section>
          <Section className={"about-section"}>
            <Grid stackable={true}>
              <Grid.Column width={8}>
                <FadeReveal duration={1250} triggerOnce={true} delay={0}>
                  <div>
                    <Header
                      as={"h2"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_header",
                        defaultValue: "About Us",
                      })}
                    />
                    <Header
                      as={"h3"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_subheader",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_description",
                      })}
                    />
                    <Button
                      primary={true}
                      to={"/about/"}
                      as={InternalLink}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "see_more_about_button",
                        defaultValue: "Learn More About Us",
                      })}
                      event={{ category: "About", action: "View About" }}
                    />
                  </div>
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <BasicCarousel
                    className={"about-section-image-carousel"}
                    size={1}
                    slide={true}
                    displayIndicators={false}
                    displayPlayPauseButton={true}
                    autoScroll={false}
                    leftArrowIcon={"arrow-left"}
                    rightArrowIcon={"arrow-right"}
                    mobile={{ size: 1 }}
                    items={componentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "ImageCollection",
                        componentIdentifier: "about_section_images",
                      }),
                      component: (
                        <Image
                          background={true}
                          className={"single-item-carousel-image"}
                        />
                      ),
                      propMap: { src: "__all__" },
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"reviews-section"}>
            <Image
              background={true}
              src={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentIdentifier: "reviews_section_background_image",
                numToSelect: 1,
              })}
            >
              <Header
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "reviews_section_header",
                  defaultValue: "What Our Clients Say",
                })}
              />
              <BasicCarousel
                size={1}
                displayIndicators={true}
                stepSize={1}
                autoScroll={false}
                leftArrowIcon={"arrow-left"}
                rightArrowIcon={"arrow-right"}
                displayPlayPauseButton={false}
                slide={true}
                items={multipleComponentIterator({
                  iterator: fishermanBusiness.reviews,
                  components: [
                    {
                      component: <ReviewV2 />,
                      propMap: { key: "_id" },
                      children: [
                        {
                          component: <ReviewV2.Text charLimit={440} />,
                          propMap: { text: "text" },
                        },
                        {
                          component: <ReviewV2.Line />,
                          children: [
                            {
                              component: <ReviewV2.Rating />,
                              propMap: { rating: "rating" },
                            },
                            {
                              component: <ReviewV2.RatingLabel />,
                              propMap: { rating: "rating" },
                            },
                          ],
                        },
                        {
                          component: <ReviewV2.Line />,
                          children: [
                            {
                              component: <ReviewV2.Author />,
                              propMap: { author: "author" },
                            },
                            {
                              component: <ReviewV2.Source />,
                              propMap: { source: "source", link: "link" },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                })}
              />
              <CustomerReviewModal
                businessId={fishermanBusiness._id}
                locations={allFishermanBusinessLocation.nodes}
                businessType={fishermanBusiness.type}
                triggerButtonProps={{ secondary: true }}
                buttonText={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "submit_review_button",
                  defaultValue: "Submit Review",
                })}
              />
            </Image>
          </Section>
          <Section className={"team-section"}>
            <ScrollableContent
              direction={"horizontal"}
              distanceFactor={"snap"}
              intervalFactor={500}
            >
              <Grid stackable={true} columns={2}>
                <Grid.Column className={"content-column"} width={4}>
                  <FishermanHeader
                    as={"h2"}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "staff_section_header",
                      defaultValue: "Our Team",
                    })}
                  />
                  <FishermanHeader
                    as={"h3"}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "staff_section_subheader",
                      defaultValue: "Boost Your True Beauty",
                    })}
                  />
                  <MarkdownContent
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "staff_section_description",
                    })}
                  />
                  <Button
                    as={InternalLink}
                    to={"/team/"}
                    primary={true}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "see_all_team_button",
                      defaultValue: "See Our Team",
                    })}
                    className={"see-more-button"}
                    event={{
                      category: "Team",
                      action: "View All Team Members",
                    }}
                  />
                  <div className={"triggers-container"}>
                    <ScrollableContent.PreviousTrigger />
                    <ScrollableContent.NextTrigger />
                  </div>
                </Grid.Column>
                <Grid.Column className={"team-member-cards-column"} width={12}>
                  <ScrollableContent.Content hideScrollbar={true}>
                    <FadeReveal className={"ui card"} triggerOnce={true}>
                      <FullImageCard
                        link={true}
                        className={"team-member-card"}
                        to={"/alpharetta-team/"}
                        image={
                          "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/Alpharetta-min.png"
                        }
                        as={InternalLink}
                        event={{ category: "Team", action: "View Team Member" }}
                      >
                        <TeamMemberV2.Name name={"Alpharetta"} />
                      </FullImageCard>
                      <FullImageCard
                        link={true}
                        className={"team-member-card"}
                        to={"/atlanta-team/"}
                        image={
                          "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/Atlanta-min.jpg"
                        }
                        as={InternalLink}
                        event={{ category: "Team", action: "View Team Member" }}
                      >
                        <TeamMemberV2.Name name={"Atlanta"} />
                      </FullImageCard>
                      <FullImageCard
                        link={true}
                        to={"/east-cobb-team/"}
                        className={"team-member-card"}
                        image={
                          "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/East_Cobb-min.png"
                        }
                        as={InternalLink}
                        event={{ category: "Team", action: "View Team Member" }}
                      >
                        <TeamMemberV2.Name name={"East Cobb"} />
                      </FullImageCard>
                      <FullImageCard
                        link={true}
                        className={"team-member-card"}
                        to={"/newnan/"}
                        image={
                          "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/Newnan-min.jpg"
                        }
                        as={InternalLink}
                        event={{ category: "Team", action: "View Team Member" }}
                      >
                        <TeamMemberV2.Name name={"Newnan"} />
                      </FullImageCard>
                      <FullImageCard
                        link={true}
                        className={"team-member-card"}
                        to={"/peachtree-city/"}
                        image={
                          "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/Peachtree_City-min.jpg"
                        }
                        as={InternalLink}
                        event={{ category: "Team", action: "View Team Member" }}
                      >
                        <TeamMemberV2.Name name={"Peachtree City"} />
                      </FullImageCard>
                      <FullImageCard
                        link={true}
                        className={"team-member-card"}
                        to={"/roswell-team/"}
                        image={
                          "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/Roswell-min.jpg"
                        }
                        as={InternalLink}
                        event={{ category: "Team", action: "View Team Member" }}
                      >
                        <TeamMemberV2.Name name={"Roswell"} />
                      </FullImageCard>
                      <FullImageCard
                        link={true}
                        className={"team-member-card"}
                        to={"/smyrna-team/"}
                        image={
                          "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/Smyrna-min.jpg"
                        }
                        as={InternalLink}
                        event={{ category: "Team", action: "View Team Member" }}
                      >
                        <TeamMemberV2.Name name={"Smyrna"} />
                      </FullImageCard>
                      <FullImageCard
                        link={true}
                        className={"team-member-card"}
                        to={"/west-cobb-team/"}
                        image={
                          "https://fisherman.gumlet.io/public/ba84e344-aad3-455a-a77a-b8dbcb74f6f0/West_Cobb-min.jpg"
                        }
                        as={InternalLink}
                        event={{ category: "Team", action: "View Team Member" }}
                      >
                        <TeamMemberV2.Name name={"West Cobb"} />
                      </FullImageCard>
                    </FadeReveal>
                  </ScrollableContent.Content>
                  <div className={"actions-container"}>
                    <Button
                      as={InternalLink}
                      to={"/team/"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "see_all_team_button",
                        defaultValue: "See Our Team",
                      })}
                      className={"see-more-button"}
                      primary={true}
                    />
                    <div className={"triggers-container"}>
                      <ScrollableContent.PreviousTrigger />
                      <ScrollableContent.NextTrigger />
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
            </ScrollableContent>
          </Section>
          <div
            className={"elfsight-app-5ecf6333-c43f-4ab2-8711-c787b12d4820"}
            data-elfsight-app-lazy={true}
          />
          <Section className={"locations-section"}>
            <Grid columns={2} stackable={true}>
              <Grid.Column width={8} className={"carousel-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={0}>
                  <BasicCarousel
                    className={"locations-section-image-carousel"}
                    size={1}
                    slide={true}
                    displayIndicators={false}
                    displayPlayPauseButton={false}
                    autoScroll={false}
                    mobile={{ size: 1 }}
                    items={componentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "ImageCollection",
                        componentIdentifier: "locations_section_images",
                      }),
                      component: (
                        <Image
                          background={true}
                          className={"single-item-carousel-image"}
                        />
                      ),
                      propMap: { src: "__all__" },
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8} className={"information-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <div className={"information-content"}>
                    <Header
                      as={"h2"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "locations_section_header",
                      })}
                    />
                    <Header
                      as={"h3"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "locations_section_subheader",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "locations_section_description",
                      })}
                    />
                    <Button
                      primary={true}
                      as={InternalLink}
                      to={"/locations/"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "see_all_locations_button",
                        defaultValue: "Discover Our Locations",
                      })}
                      event={{
                        category: "Locations",
                        action: "View Locations",
                      }}
                    />
                  </div>
                  <Card.Group className={"locations-cards"}>
                    <MultipleComponentIterator
                      iterator={allFishermanBusinessLocation.nodes}
                      components={[
                        {
                          component: <Card />,
                          propMap: { key: "_id" },
                          children: [
                            {
                              component: <Card.Content />,
                              children: [
                                {
                                  component: <Card.Header />,
                                  propMap: { content: "name" },
                                },
                                {
                                  component: <Card.Description />,
                                  children: [
                                    {
                                      component: <Location inline={true} />,
                                      propMap: {
                                        "address.street": "street",
                                        "address.city": "city",
                                        "address.state": "state",
                                        "address.zip": "zipCode",
                                      },
                                    },
                                    {
                                      component: <Email />,
                                      propMap: { email: "email" },
                                    },
                                    {
                                      component: <Phone />,
                                      propMap: { phone: "phoneNumber" },
                                    },
                                  ],
                                },
                                {
                                  component: (
                                    <HoursOfOperation
                                      displayOption={"dailyWithPopup"}
                                    />
                                  ),
                                  propMap: { hours: "hours" },
                                },
                              ],
                            },
                          ],
                        },
                      ]}
                    />
                  </Card.Group>
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"trusted-by-section"}>
            <ContinuousScroll animationDuration={30}>
              <PatternedImageRow
                containerAs={ContinuousScroll.Item}
                imageSizePatterns={[
                  { width: 100, height: 50, alignment: "middle" },
                ]}
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "ImageCollection",
                  componentIdentifier: "trusted_by_images",
                })}
              />
            </ContinuousScroll>
          </Section>
          <Grid
            stackable={true}
            className={"custom component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <Button
                basic={true}
                size={"large"}
                to={
                  "https://docs.google.com/forms/d/e/1FAIpQLSfYBb4eUE8nC63OzDEaIsgL8If45waeixFFakAx2uK8NtH2vw/viewform?usp=sf_link"
                }
                as={OutboundLink}
              >
                Need A Hair Adjustment?
              </Button>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          _id
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    dynamicTilesComponent: fishermanWebsiteComponent(
      page: { pageType: { eq: "Home" } }
      fastId: { eq: "DynamicTiles" }
    ) {
      childConfigurations {
        data {
          className
          overlayClassName
          width
          video {
            videoId
            url
            source
            placeholder
          }
        }
        files {
          _id
          altText
          file
          order
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          _id
          content
          contentType
          contentName
          order
        }
        interactions {
          _id
          displayType
          behaviorType
          enabled
          label
          order
          style
          url
        }
      }
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        _id
        name
        files
        description
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
        state
        zipCode
        email
        hours {
          open
          close
          day
          label
        }
      }
    }
  }
`;
